// extracted by mini-css-extract-plugin
export var gridContainer = "hamburgerMenu-module--gridContainer--3MO-7";
export var layoutContainer = "hamburgerMenu-module--layoutContainer--sLePu";
export var link = "hamburgerMenu-module--link--srsPR";
export var icon = "hamburgerMenu-module--icon--XT53C";
export var hamburger = "hamburgerMenu-module--hamburger--MqCnr";
export var nav = "hamburgerMenu-module--nav--1vFP3";
export var ul = "hamburgerMenu-module--ul--iQOxB";
export var li = "hamburgerMenu-module--li--jZp-n";
export var a = "hamburgerMenu-module--a--ipMj9";
export var menuOpen = "hamburgerMenu-module--menuOpen--GS0Kc";
export var aboutOutline = "hamburgerMenu-module--aboutOutline--gdXS8";
export var projectsOutline = "hamburgerMenu-module--projectsOutline--G0Jd3";
export var contactOutline = "hamburgerMenu-module--contactOutline--cQn+g";