// extracted by mini-css-extract-plugin
export var gridContainer = "contact-module--gridContainer--AIem7";
export var layoutContainer = "contact-module--layoutContainer--c4Vm2";
export var link = "contact-module--link--+Crax";
export var sidebar___contact = "contact-module--sidebar___contact--+xNx1";
export var corner = "contact-module--corner--tBj3D";
export var navLink = "contact-module--navLink--LtfI5";
export var activeLink = "contact-module--activeLink--EAQwg";
export var projectsOutline = "contact-module--projectsOutline--ua028";
export var center = "contact-module--center--vFhqM";
export var instagram = "contact-module--instagram--WzaJa";
export var instagramLink = "contact-module--instagramLink--6c6sE";
export var contact = "contact-module--contact--80FSR";
export var emailLink = "contact-module--emailLink--xLJRd";
export var footerTop = "contact-module--footerTop--XP3hT";
export var aboutOutline = "contact-module--aboutOutline--znpis";
export var contactOutline = "contact-module--contactOutline--wAvMf";